<template>
  <svg
    :class="[color, 'logoContainer']"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1249.34 200.09"
  >
    <g data-name="ennoshita">
      <path
        d="M125.49,134.79v4.13a33.55,33.55,0,0,1-.26,4.13H31a30.61,30.61,0,0,0,3.48,12.27,31.27,31.27,0,0,0,7.88,9.55,39.16,39.16,0,0,0,11.1,6.33,36.65,36.65,0,0,0,12.91,2.32q11.63,0,19.63-4.26a36.85,36.85,0,0,0,10.06-7.82,5.23,5.23,0,0,1,7.16-.69L119.81,174q-18.33,24.78-53.19,24.79A75.74,75.74,0,0,1,40,194.31a62.69,62.69,0,0,1-21-12.78A57.5,57.5,0,0,1,5,161.25,69.77,69.77,0,0,1,0,134a71.06,71.06,0,0,1,5-27.24,60.65,60.65,0,0,1,34.6-34.34,69.42,69.42,0,0,1,26-4.78,65.46,65.46,0,0,1,23.88,4.26,51.75,51.75,0,0,1,19,12.66A58.51,58.51,0,0,1,121,105.48Q125.49,118,125.49,134.79Zm-31-13.95a38.23,38.23,0,0,0-1.68-11.36A25.41,25.41,0,0,0,78.37,93.34a32.17,32.17,0,0,0-13-2.45q-13.95,0-23.63,8.39A30.58,30.58,0,0,0,31,120.84Z"
      />
      <path
        d="M734.67,102.56a2.53,2.53,0,0,1-3.56-.14,38.27,38.27,0,0,0-9.78-7.53,32.07,32.07,0,0,0-15.75-4A30.11,30.11,0,0,0,691.89,94q-6.2,3.09-6.2,10.33t6.85,10.2q6.84,3,20,6.06a114.46,114.46,0,0,1,14.07,4.14,51.36,51.36,0,0,1,12.91,6.84A33.88,33.88,0,0,1,749,142.15a30.62,30.62,0,0,1,3.61,15.36q0,11.37-4.26,19.24A36.23,36.23,0,0,1,737,189.53a47.89,47.89,0,0,1-16.53,7.1,85.82,85.82,0,0,1-19.49,2.2,77.38,77.38,0,0,1-28.15-5.3,60.28,60.28,0,0,1-20.89-13.21,2.59,2.59,0,0,1,.09-3.7l16.48-15.43a2.54,2.54,0,0,1,3.64.12,41.48,41.48,0,0,0,11.79,9.11,38.39,38.39,0,0,0,18.33,4.39,31.88,31.88,0,0,0,6.84-.77,23.37,23.37,0,0,0,6.46-2.46,13.26,13.26,0,0,0,4.77-4.52,12.66,12.66,0,0,0,1.81-7q0-7.74-7.1-11.1t-21.3-6.71a102.8,102.8,0,0,1-13.56-4,44,44,0,0,1-11.75-6.45,30,30,0,0,1-8.26-9.94q-3.11-5.94-3.1-14.72a35.5,35.5,0,0,1,4.26-17.82,36.61,36.61,0,0,1,11.23-12.26A50.17,50.17,0,0,1,688.27,70a70.31,70.31,0,0,1,18.08-2.33,76.11,76.11,0,0,1,26.21,4.65,45.06,45.06,0,0,1,18.66,12.27,2.56,2.56,0,0,1-.28,3.6Z"
      />
      <path
        d="M862.43,67.65q12.14,0,20.79,4.26a41.11,41.11,0,0,1,14.2,11.23,45.49,45.49,0,0,1,8.13,16,67.48,67.48,0,0,1,2.59,18.6v74.88a2.59,2.59,0,0,1-2.59,2.58H879.73a2.59,2.59,0,0,1-2.58-2.58V127a84.42,84.42,0,0,0-.78-11.23,32.39,32.39,0,0,0-3.22-10.59,21.25,21.25,0,0,0-7-7.87q-4.53-3.1-12-3.1a27.49,27.49,0,0,0-12.91,2.84,26.6,26.6,0,0,0-9,7.62,33.81,33.81,0,0,0-5.42,10.71A41.33,41.33,0,0,0,825,127.56v65.07a2.59,2.59,0,0,1-2.58,2.58H796.59a2.59,2.59,0,0,1-2.59-2.58V2.58A2.59,2.59,0,0,1,796.59,0h25.82A2.59,2.59,0,0,1,825,2.58v86h.52A29.82,29.82,0,0,1,830.8,81a41.78,41.78,0,0,1,8.13-6.71,42.79,42.79,0,0,1,10.59-4.78A44.31,44.31,0,0,1,862.43,67.65Z"
      />
      <path
        d="M958.6,71.27h25.82A2.58,2.58,0,0,1,987,73.85V192.63a2.59,2.59,0,0,1-2.58,2.58H958.6a2.59,2.59,0,0,1-2.59-2.58V73.85A2.58,2.58,0,0,1,958.6,71.27Z"
      />
      <path
        d="M1022.37,93.47V73.85a2.58,2.58,0,0,1,2.58-2.58h19.11V38a2.59,2.59,0,0,1,2.58-2.59H1072a2.58,2.58,0,0,1,2.58,2.59V71.27h28.41a2.58,2.58,0,0,1,2.58,2.58V93.47a2.58,2.58,0,0,1-2.58,2.58h-28.41v57.59q0,8.27,3,13.68t13.3,5.42a38.27,38.27,0,0,0,6.71-.64,28.55,28.55,0,0,0,3.36-.79,2.57,2.57,0,0,1,3.25,2.39l.88,20.74a51.76,51.76,0,0,1-9.81,2.45,69.39,69.39,0,0,1-10.85.9q-12.39,0-20.14-3.48a30.55,30.55,0,0,1-12.26-9.56,35.24,35.24,0,0,1-6.2-13.94,81,81,0,0,1-1.68-16.91V96.05H1025A2.58,2.58,0,0,1,1022.37,93.47Z"
      />
      <path
        d="M1220.42,179.46h-.77q-4.65,8.26-15,13.56a50.92,50.92,0,0,1-23.5,5.29,67.47,67.47,0,0,1-15.62-1.94,46.4,46.4,0,0,1-15-6.45A37.49,37.49,0,0,1,1139.21,178q-4.51-7.36-4.52-18.21,0-13.94,7.88-22.2A51.08,51.08,0,0,1,1162.84,125a114.22,114.22,0,0,1,27.5-5.8q15.11-1.42,29.57-1.42v-3.1q0-11.62-8.4-17.17t-20-5.56a44.93,44.93,0,0,0-18.85,4.13,50.64,50.64,0,0,0-15,10.08l-16-18.85a69.86,69.86,0,0,1,24.4-14.72,83.1,83.1,0,0,1,28-4.91q16.53,0,27.25,4.65t16.91,12.13a43.55,43.55,0,0,1,8.65,16.79,72.88,72.88,0,0,1,2.45,18.59v72.82a2.58,2.58,0,0,1-2.58,2.58H1223a2.59,2.59,0,0,1-2.58-2.58Zm-.51-40.8h-7q-7.48,0-15.75.65a68.52,68.52,0,0,0-15.23,2.84,32.59,32.59,0,0,0-11.62,6.19c-3.1,2.68-4.65,6.33-4.65,11a13.2,13.2,0,0,0,1.94,7.36,14.82,14.82,0,0,0,5,4.77,22.5,22.5,0,0,0,7,2.59,40,40,0,0,0,7.75.77q16,0,24.27-8.52t8.27-23.24Z"
      />
      <path
        d="M331.91,195.21h25.83a2.59,2.59,0,0,0,2.58-2.58V134.51a42.91,42.91,0,0,1,1.8-12.26,33.65,33.65,0,0,1,5.3-10.71,27.32,27.32,0,0,1,8.91-7.62,26.45,26.45,0,0,1,12.91-3q7.49,0,12,3.1a22.25,22.25,0,0,1,7.1,7.87,30.62,30.62,0,0,1,3.36,10.72,87.27,87.27,0,0,1,.78,11.36v58.63a2.58,2.58,0,0,0,2.58,2.58h25.82a2.58,2.58,0,0,0,2.58-2.58V102a67.41,67.41,0,0,0-2.58-18.59A47.81,47.81,0,0,0,438.57,77a2.58,2.58,0,0,0-2.78-1.45,756.7,756.7,0,0,1-104,10.33,2.57,2.57,0,0,0-2.5,2.57V192.63A2.59,2.59,0,0,0,331.91,195.21Z"
      />
      <path
        d="M166.66,195.21h25.82a2.59,2.59,0,0,0,2.58-2.58V134.51a42.58,42.58,0,0,1,1.81-12.26,33.63,33.63,0,0,1,5.29-10.71,27.32,27.32,0,0,1,8.91-7.62A26.45,26.45,0,0,1,224,101q7.49,0,12,3.1a22.34,22.34,0,0,1,7.1,7.87,30.61,30.61,0,0,1,3.35,10.72,87.27,87.27,0,0,1,.78,11.36v58.63a2.58,2.58,0,0,0,2.58,2.58h25.82a2.58,2.58,0,0,0,2.58-2.58V102a67.16,67.16,0,0,0-1.6-14.55,2.59,2.59,0,0,0-2.41-2A753.85,753.85,0,0,1,167.13,72.89a2.57,2.57,0,0,0-3.06,2.52V192.63A2.59,2.59,0,0,0,166.66,195.21Z"
      />
      <path
        d="M555.67,65.92A67.14,67.14,0,1,0,619,136.63,67.13,67.13,0,0,0,555.67,65.92ZM550,170.11A37.21,37.21,0,1,1,589.15,135,37.21,37.21,0,0,1,550,170.11Z"
      />
      <circle cx="971.79" cy="30.65" r="18.41" />
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: 'white'
    }
  }
}
</script>

<style scoped lang="scss">
.logoContainer {
  transition: fill 0.5s ease-in-out;
}
.white {
  fill: $color_fill_white_100;
}
.black {
  fill: $color_fill_black_100;
}
.green {
  fill: $color_fill_green_100;
}
.gray {
  fill: $color_fill_black_060;
}
</style>
