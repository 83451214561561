export const buildAdminUrl = (path) => `${process.env.ADMIN_DOMAIN}${path}`
export const buildJoinUrl = (path) => `${process.env.JOIN_DOMAIN}${path}`
export const removeHTMLTag = (html) => {
  if (!html) {
    return ''
  }

  return html
    .replace(/<.+?>/g, '')
    .replace(/ /g, '')
    .replace(/\r?\n/g, '')
}

const sendClickEventToGA = (ga, name) => {
  ga.event('Click', {
    event_category: 'Button',
    event_label: name
  })
}

export const sendClickEditorEventToGA = (ga) => {
  sendClickEventToGA(ga, 'push-to-projects')
}

export const sendClickCheckPriceEventToGA = (ga) => {
  sendClickEventToGA(ga, 'push-to-checkprice')
}

export const sendClickLoginEventToGA = (ga) => {
  sendClickEventToGA(ga, 'push-to-login')
}

export const sendClickDemoEventToGA = (ga) => {
  sendClickEventToGA(ga, 'push-to-demo')
}
