<template>
  <br class="mobile-break" />
</template>

<script>
export default {
  components: {},
  props: {}
}
</script>

<style scoped lang="scss">
.mobile-break {
  display: none;
  @include for_mobile {
    display: block;
  }
}
</style>
