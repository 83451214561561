<template>
  <section
    id="conversion-section"
    :style="
      'background-image:url(' +
        require(`~/assets/images/pattern_transparent_green.png`) +
        ')'
    "
  >
    <div class="content-wrapper">
      <h1>
        <div class="h1-child">
          <Typography
            type="heading"
            typelevel="3"
            color="black"
            colorscale="100"
            sptype="heading"
            sptypelevel="4"
            >参加者</Typography
          >
          <Typography
            type="heading"
            typelevel="1"
            color="black"
            colorscale="100"
            sptype="heading"
            sptypelevel="3"
            >1</Typography
          >
          <Typography
            type="heading"
            typelevel="3"
            color="black"
            colorscale="100"
            sptype="heading"
            sptypelevel="4"
            >人あたり</Typography
          >
        </div>
        <div class="h1-child">
          <Typography
            type="heading"
            typelevel="1"
            color="black"
            colorscale="100"
            sptype="heading"
            sptypelevel="3"
            >200</Typography
          >
          <Typography
            type="heading"
            typelevel="3"
            color="black"
            colorscale="100"
            sptype="heading"
            sptypelevel="4"
            >円以下で</Typography
          >
          <Typography
            type="heading"
            typelevel="3"
            color="black"
            colorscale="100"
            sptype="heading"
            sptypelevel="4"
            class="desktop"
            >ご利用できます</Typography
          >
        </div>
        <div class="h1-child">
          <Typography
            type="heading"
            typelevel="3"
            color="black"
            colorscale="100"
            sptype="heading"
            sptypelevel="4"
            class="mobile"
            >ご利用できます</Typography
          >
        </div>
      </h1>
      <Typography
        type="subheading"
        typelevel="3"
        color="black"
        colorscale="060"
        sptype="text"
        sptypelevel="1"
      >
        安心プラン(税込15187円)を<MobileBreak />参加者80人で利用した場合の参考価格
      </Typography>
      <div class="conversion-button-list">
        <a :href="signupUrl" @click="handleSignup">
          <Button type="primary" color="dark-red">まずは無料でお試し </Button>
        </a>
        <Button type="primary" color="dark-green" @click="handleCheckPrice"
          >プランを確認する</Button
        >
      </div>
    </div>
  </section>
</template>

<script>
import Typography from '../../general/Typography.vue'
import Button from '../../general/Button.vue'
import MobileBreak from '../../general/MobileBreak.vue'
export default {
  components: { Typography, Button, MobileBreak },
  props: {
    signupUrl: {
      type: String,
      required: true
    }
  },
  methods: {
    handleSignup() {
      this.$emit('onSignup')
    },
    handleCheckPrice() {
      this.$emit('onCheckPrice')
      const targetTop =
        document.getElementById('prices-section').getBoundingClientRect().top +
        window.scrollY -
        64
      window.scrollTo({
        top: targetTop,
        behavior: 'smooth'
      })
    }
  }
}
</script>

<style scoped lang="scss">
#conversion-section {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: $size_inserted_section_desktop_height;
  background-color: $color_fill_green_010;
  background-size: 580px;
  background-position: center;
  @include for_mobile {
    height: auto;
    background-size: 400px;
    padding-top: $size_section_mobile_padding_top;
    padding-bottom: $size_section_mobile_padding_bottom;
  }
  .content-wrapper {
    max-width: $size_breakpoint_desktop;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: px_unit(2);

    h1 {
      display: flex;
      flex-direction: row;
      gap: px_unit(0.5);
      align-items: baseline;
      @include for_tablet {
        flex-direction: column;
        align-items: center;
        gap: px_unit(1);
      }
      @include for_mobile {
        flex-direction: column;
        align-items: center;
        gap: px_unit(1);
      }
      .h1-child {
        display: flex;
        flex-direction: row;
        gap: px_unit(0.5);
        align-items: baseline;
        .desktop {
          display: flex;
        }
        .mobile {
          display: none;
        }
        @include for_mobile {
          .desktop {
            display: none;
          }
          .mobile {
            display: flex;
          }
        }
      }
    }
    .conversion-button-list {
      display: flex;
      flex-direction: row;
      gap: px_unit(2);
      margin-top: px_unit(3);
      @include for_mobile {
        flex-direction: column;
        gap: px_unit(1.5);
        margin-top: px_unit(2);
      }
    }
  }
}
</style>
