var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"typography-wrapper"},[_c('p',{class:[
      _vm.sptype == null || _vm.sptypelevel == null
        ? [
            'typography-' + _vm.type,
            'typography-' + _vm.type + '-level-' + _vm.typelevel,
            'color-' + _vm.color + '-' + _vm.colorscale
          ]
        : [
            'typography-' + _vm.type,
            'typography-' + _vm.type + '-level-' + _vm.typelevel,
            'typography-sp-' + _vm.sptype,
            'typography-sp-' + _vm.sptype + '-level-' + _vm.sptypelevel,
            'color-' + _vm.color + '-' + _vm.colorscale
          ]
    ]},[_vm._t("default")],2)])}
var staticRenderFns = []

export { render, staticRenderFns }