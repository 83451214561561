<template>
  <footer class="footer">
    <a href="/">
      <Logo color="gray" class="footer-logo" />
    </a>
    <nav class="footer-nav">
      <nuxt-link to="/media">
        <TextLink color="gray">コラム</TextLink>
      </nuxt-link>
      <nuxt-link to="/media/SBhMGcXw7SeO9QXUFh6oi">
        <TextLink color="gray">よくある質問</TextLink>
      </nuxt-link>
      <nuxt-link to="/privacy">
        <TextLink color="gray">プライバシーポリシー</TextLink>
      </nuxt-link>
      <nuxt-link to="/terms">
        <TextLink color="gray">利用規約</TextLink>
      </nuxt-link>
    </nav>
    <div class="footer-copyright">
      <div class="footer-copyright-love">
        <Typography
          type="text"
          typelevel="2"
          color="black"
          colorscale="060"
          sptype="text"
          sptypelevel="3"
          >Made with</Typography
        >
        <img
          :src="require(`~/assets/images/icon-red-favorite.png`)"
          alt="Love"
        />
        <Typography
          type="text"
          typelevel="2"
          color="black"
          colorscale="060"
          sptype="text"
          sptypelevel="3"
          >from Tokyo</Typography
        >
      </div>
      <Typography
        type="text"
        typelevel="2"
        color="black"
        colorscale="060"
        sptype="text"
        sptypelevel="3"
        >{{ `©${todayYear} Ennoshita` }}</Typography
      >
    </div>
  </footer>
</template>

<script>
import TextLink from '../../general/TextLink.vue'
import Typography from '../../general/Typography.vue'
import Logo from '../../general/Logo.vue'

export default {
  components: { Typography, TextLink, Logo },
  props: {},
  computed: {
    todayYear() {
      return new Date().getFullYear()
    }
  }
}
</script>

<style scoped lang="scss">
.footer {
  background-color: $color_fill_black_100;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: px_unit(7) 0 px_unit(7);
  gap: px_unit(3);
  @include for_mobile {
    padding: px_unit(5) 0 px_unit(5);
    gap: px_unit(3);
  }
  &-logo {
    height: px_unit(4);
    @include for_mobile {
      height: px_unit(3.5);
    }
  }
  &-nav {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: px_unit(4);
    @include for_mobile {
      flex-direction: column;
      gap: px_unit(1);
    }
  }
  &-copyright {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: px_unit(1);
    @include for_mobile {
      gap: px_unit(0.5);
    }
    &-love {
      text-align: center;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: px_unit(1);
      @include for_mobile {
        gap: px_unit(0.5);
      }
      img {
        width: 2rem;
        height: 2rem;
        transform: translateY(-0.1rem);
        @include for_mobile {
          width: 1.4rem;
          height: 1.4rem;
          transform: translateY(0);
        }
      }
    }
  }
}
</style>
