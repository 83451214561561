<template>
  <div :class="['text-link', color]">
    <Typography type="button" typelevel="1" sptype="button" sptypelevel="2">
      <slot />
    </Typography>
  </div>
</template>

<script>
import Typography from './Typography.vue'

export default {
  components: { Typography },
  props: {
    color: {
      type: String,
      required: true
    }
  }
}
</script>

<style scoped lang="scss">
.text-link {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease-in-out;
}

.green {
  color: $color_text_green_100;
  &:hover,
  &:focus {
    color: $color_text_green_060;
  }
  &:active {
    color: $color_text_green_010;
  }
  &:disabled {
    color: $color_text_green_010;
  }
}
.red {
  color: $color_text_red_100;
  &:hover,
  &:focus {
    color: $color_text_red_060;
  }
  &:active {
    color: $color_text_red_010;
  }
  &:disabled {
    color: $color_text_red_010;
  }
}
.black {
  color: $color_text_black_100;
  &:hover,
  &:focus {
    color: $color_text_black_060;
  }
  &:active {
    color: $color_text_black_010;
  }
  &:disabled {
    color: $color_text_black_010;
  }
}
.gray {
  color: $color_text_black_060;
  &:hover,
  &:focus {
    color: $color_text_black_030;
  }
  &:active {
    color: $color_text_black_010;
  }
  &:disabled {
    color: $color_text_black_010;
  }
}
</style>
