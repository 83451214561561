<template>
  <button
    :class="['button', 'button-' + type, color]"
    ontouchstart=""
    @click="$emit('click', $event)"
  >
    <Typography type="button" typelevel="1">
      <slot />
    </Typography>
  </button>
</template>

<script>
import Typography from './Typography.vue'

export default {
  components: { Typography },
  props: {
    type: {
      type: String,
      required: true
    },
    color: {
      type: String,
      required: true
    }
  }
}
</script>

<style scoped lang="scss">
.button {
  cursor: pointer;
  border: 0;
  outline: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 30px;
  width: $size_button_desktop_width;
  height: $size_button_desktop_height;
  @media (hover: hover) {
    transition: all 0.2s ease-in-out;
  }

  &-primary {
    border: 1px solid rgba(63, 75, 64, 0.04);
    transform: translateY(0px);
    @include box_shadow_1($color_fill_black_100);
    @media (hover: hover) {
      &:hover,
      &:focus {
        transform: translateY(-3px);
        @include box_shadow_2($color_fill_black_100);
      }
    }
    &:active {
      transform: translateY(0px);
      box-shadow: none;
    }
    &:disabled {
      box-shadow: none;
    }
  }
}

.dark-green {
  background-color: $color_fill_green_100;
  color: $color_text_white_100;
  @media (hover: hover) {
    &:hover,
    &:focus {
      background-color: $color_fill_green_060;
    }
  }
  &:active {
    background-color: $color_fill_green_030;
  }
  &:disabled {
    background-color: $color_fill_green_010;
    color: $color_text_green_030;
  }
}
.dark-red {
  background-color: $color_fill_red_100;
  color: $color_text_white_100;
  @media (hover: hover) {
    &:hover,
    &:focus {
      background-color: $color_fill_red_060;
    }
  }
  &:active {
    background-color: $color_fill_red_030;
  }
  &:disabled {
    background-color: $color_fill_red_010;
    color: $color_text_red_030;
  }
}
.dark-black {
  background-color: $color_fill_black_100;
  color: $color_text_white_100;
  @media (hover: hover) {
    &:hover,
    &:focus {
      background-color: $color_fill_black_060;
    }
  }
  &:active {
    background-color: $color_fill_black_030;
  }
  &:disabled {
    background-color: $color_fill_black_010;
    color: $color_text_black_030;
  }
}
.light-green {
  background-color: $color_fill_green_010;
  color: $color_text_green_100;
  @media (hover: hover) {
    &:hover,
    &:focus {
      background-color: $color_fill_white_100;
      color: $color_text_green_060;
    }
  }
  &:active {
    background-color: $color_fill_white_100;
    color: $color_text_green_010;
  }
  &:disabled {
    background-color: $color_fill_white_100;
    color: $color_text_green_030;
  }
}
.light-red {
  background-color: $color_fill_red_010;
  color: $color_text_red_100;
  @media (hover: hover) {
    &:hover,
    &:focus {
      background-color: $color_fill_white_100;
      color: $color_text_red_060;
    }
  }
  &:active {
    background-color: $color_fill_white_100;
    color: $color_text_red_010;
  }
  &:disabled {
    background-color: $color_fill_white_100;
    color: $color_text_red_030;
  }
}
.light-black {
  background-color: $color_fill_black_010;
  color: $color_text_black_100;
  @media (hover: hover) {
    &:hover,
    &:focus {
      background-color: $color_fill_white_100;
      color: $color_text_black_060;
    }
  }
  &:active {
    background-color: $color_fill_white_100;
    color: $color_text_black_010;
  }
  &:disabled {
    background-color: $color_fill_white_100;
    color: $color_text_black_030;
  }
}
</style>
