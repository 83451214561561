<template>
  <div class="typography-wrapper">
    <p
      :class="[
        sptype == null || sptypelevel == null
          ? [
              'typography-' + type,
              'typography-' + type + '-level-' + typelevel,
              'color-' + color + '-' + colorscale
            ]
          : [
              'typography-' + type,
              'typography-' + type + '-level-' + typelevel,
              'typography-sp-' + sptype,
              'typography-sp-' + sptype + '-level-' + sptypelevel,
              'color-' + color + '-' + colorscale
            ]
      ]"
    >
      <slot />
    </p>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      required: true
    },
    typelevel: {
      type: String,
      required: true
    },
    color: {
      type: String,
      default: null
    },
    colorscale: {
      type: String,
      default: null
    },
    sptype: {
      type: String,
      default: null
    },
    sptypelevel: {
      type: String,
      default: null
    }
  }
}
</script>

<style scoped lang="scss">
@mixin typography_style {
  &-heading {
    font-weight: bold;
    letter-spacing: 0.12em;
    line-height: 1.6;
    &-level-1 {
      font-size: $font_size_heading_1;
      margin: calc(
          (1.6 * #{$font_size_heading_1} - #{$font_size_heading_1}) / 2 * -1
        )
        0;
    }
    &-level-2 {
      font-size: $font_size_heading_2;
      margin: calc(
          (1.6 * #{$font_size_heading_2} - #{$font_size_heading_2}) / 2 * -1
        )
        0;
    }
    &-level-3 {
      font-size: $font_size_heading_3;
      margin: calc(
          (1.6 * #{$font_size_heading_3} - #{$font_size_heading_3}) / 2 * -1
        )
        0;
    }
    &-level-4 {
      font-size: $font_size_heading_4;
      margin: calc(
          (1.6 * #{$font_size_heading_4} - #{$font_size_heading_4}) / 2 * -1
        )
        0;
    }
    &-level-5 {
      font-size: $font_size_heading_5;
      margin: calc(
          (1.6 * #{$font_size_heading_5} - #{$font_size_heading_5}) / 2 * -1
        )
        0;
    }
  }
  &-subheading {
    font-weight: normal;
    letter-spacing: 0.12em;
    line-height: 1.6;
    &-level-1 {
      font-size: $font_size_subheading_1;
      margin: calc(
          (1.6 * #{$font_size_subheading_1} - #{$font_size_subheading_1}) / 2 *
            -1
        )
        0;
    }
    &-level-2 {
      font-size: $font_size_subheading_2;
      margin: calc(
          (1.6 * #{$font_size_subheading_2} - #{$font_size_subheading_2}) / 2 *
            -1
        )
        0;
    }
    &-level-3 {
      font-size: $font_size_subheading_3;
      margin: calc(
          (1.6 * #{$font_size_subheading_3} - #{$font_size_subheading_3}) / 2 *
            -1
        )
        0;
    }
  }
  &-button {
    font-weight: bold;
    letter-spacing: 0.08em;
    line-height: 1.8;
    &-level-1 {
      font-size: $font_size_button_1;
      margin: calc(
          (1.8 * #{$font_size_button_1} - #{$font_size_button_1}) / 2 * -1
        )
        0;
    }
    &-level-2 {
      font-size: $font_size_button_2;
      margin: calc(
          (1.8 * #{$font_size_button_2} - #{$font_size_button_2}) / 2 * -1
        )
        0;
    }
    &-level-3 {
      font-size: $font_size_button_3;
      margin: calc(
          (1.8 * #{$font_size_button_3} - #{$font_size_button_3}) / 2 * -1
        )
        0;
    }
  }
  &-text {
    font-weight: normal;
    letter-spacing: 0.08em;
    line-height: 1.8;
    &-level-1 {
      font-size: $font_size_text_1;
      margin: calc((1.8 * #{$font_size_text_1} - #{$font_size_text_1}) / 2 * -1)
        0;
    }
    &-level-2 {
      font-size: $font_size_text_2;
      margin: calc((1.8 * #{$font_size_text_2} - #{$font_size_text_2}) / 2 * -1)
        0;
    }
    &-level-3 {
      font-size: $font_size_text_3;
      margin: calc((1.8 * #{$font_size_text_3} - #{$font_size_text_3}) / 2 * -1)
        0;
    }
  }
}

.typography {
  &-wrapper {
    border-top: 1px solid rgba(0, 0, 0, 0);
    border-bottom: 1px solid rgba(0, 0, 0, 0);
  }
  @include typography_style;

  @include for_mobile {
    &-sp {
      @include typography_style;
    }
  }
}

.color {
  &-green {
    &-100 {
      color: $color_text_green_100;
    }
    &-060 {
      color: $color_text_green_060;
    }
    &-030 {
      color: $color_text_green_030;
    }
    &-010 {
      color: $color_text_green_010;
    }
  }
  &-red {
    &-100 {
      color: $color_text_red_100;
    }
    &-060 {
      color: $color_text_red_060;
    }
    &-030 {
      color: $color_text_red_030;
    }
    &-010 {
      color: $color_text_red_010;
    }
  }
  &-yellow {
    &-100 {
      color: $color_text_yellow_100;
    }
    &-060 {
      color: $color_text_yellow_060;
    }
    &-030 {
      color: $color_text_yellow_030;
    }
    &-010 {
      color: $color_text_yellow_010;
    }
  }
  &-blue {
    &-100 {
      color: $color_text_blue_100;
    }
    &-060 {
      color: $color_text_blue_060;
    }
    &-030 {
      color: $color_text_blue_030;
    }
    &-010 {
      color: $color_text_blue_010;
    }
  }
  &-black {
    &-100 {
      color: $color_text_black_100;
    }
    &-060 {
      color: $color_text_black_060;
    }
    &-030 {
      color: $color_text_black_030;
    }
    &-010 {
      color: $color_text_black_010;
    }
  }
  &-white {
    &-100 {
      color: $color_text_white_100;
    }
  }
}
</style>
